import "./App.css";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Header } from "./Header";

export const About = () => {
  return (
    <>
      <Stack
        mt={20}
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/fad81765-240d-4795-b0da-1f4cfd885381.jpg"
          alt="Les Gold"
          width={"40%"}
        />
        <Typography variant="h4" fontFamily={"CZ-Regular"}>
          About Les Gold
        </Typography>
        <Typography variant="body1" width={"75%"}>
          Portland indie rock outfit Les Gold was founded by frontman Jenson
          Tuomi and guitarist Tim Kaye, and consists of bassist Jake Jarvis,
          drummer Alexander Geiszler, and lead guitarist Pete Abraham. The band
          has cultivated a sound that mixes homegrown Northwest indie rock with
          elements of folk rock, dream pop, desert rock, surf, and other
          influences. Les Gold showcases catchy, poppy melodies and tones
          layered over unique vocal harmonies and often macabre lyrics –
          crafting a sound that’s been described as ‘gloom disco’ and ‘bummer
          fun’.
        </Typography>
      </Stack>
    </>
  );
};
