import "./App.css";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const Shows = () => {
  return (
    <Stack
      mt={10}
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
      }}
    >
      <img src="/IMG_4912.jpg" alt="Les Gold" width={"40%"} />
      <Typography variant="h4" fontFamily={"CZ-Regular"}>
        Shows
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1200 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#c1ddc7" }}>
              <TableCell>Venue</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Tickets</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* <TableRow
                    key={0}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: "#f5e8c6",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Mississippi Studios
                    </TableCell>
                    <TableCell align="right">Portland, Oregon</TableCell>
                    <TableCell align="right">9-21-2024</TableCell>
                    <TableCell align="right">
                      <a href="https://www.etix.com/ticket/p/48204612/les-gold-portland-mississippi-studios?_gl=1*1se6vwv*_ga*MTcwNjU0NzM5Ny4xNzI1NTYzNTA3*_ga_T3044165VM*MTcyNTU2MzUwNi4xLjAuMTcyNTU2MzUwNi42MC4wLjA.&_ga=2.46185639.1281388398.1725563507-1706547397.1725563507">
                        Tickets
                      </a>{" "}
                    </TableCell>
                  </TableRow> */}
            {/* <TableRow
                    key={0}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: "#f4d279",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Ron Toms
                    </TableCell>
                    <TableCell align="right">Portland, Oregon</TableCell>
                    <TableCell align="right">9-28-2024</TableCell>
                    <TableCell align="right">Tickets </TableCell>
                  </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
