import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { About } from "./About";
import { Shows } from "./Shows";
import { Header } from "./Header";
import Grid from "@mui/material/Grid2";

export function App() {
  return (
    <Grid
      container
      size={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="shows" element={<Shows />} />
      </Routes>
    </Grid>
  );
}

export default App;
